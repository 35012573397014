// 3rd
import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import type { RequirementOverview } from '../types/requirement';
import { ZodRequirementDto, castRequirementDtoToRequirement } from './dtos/requirement';
import {
  ZodStoryOccurrencesDto,
  castStoryOccurrencesDtoToStoryOccurrence,
} from './dtos/story-occurrences';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { SECURITY_FRAMEWORKS_QUERY_KEYS } from '../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  applicationId: z.string().optional(),
  requirementId: z.string(),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

// ############
// Response DTO
// ############

const ZodResponseDto = z.object({
  securityRequirementOverview: z.object({
    coveredStoryOccurrences: z.array(ZodStoryOccurrencesDto),
    requestedStoryOccurrences: z.array(ZodStoryOccurrencesDto),
    securityRequirement: ZodRequirementDto,
  }),
});

type ResponseDto = z.infer<typeof ZodResponseDto>;

// #######
// Request
// #######

export const getRequirementOverview = async (
  id: string,
  applicationId?: string
): Promise<RequirementOverview> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse(
      applicationId ? { applicationId, requirementId: id } : { requirementId: id }
    );
    const res = await apiClient.post(`/SecurityFramework/GetSecurityRequirementOverview`, payload);
    const parsedRes: ResponseDto = ZodResponseDto.parse(res);

    const requirement = castRequirementDtoToRequirement(
      parsedRes.securityRequirementOverview.securityRequirement
    );
    const requirementCoveredInStories =
      parsedRes.securityRequirementOverview.coveredStoryOccurrences.map(
        castStoryOccurrencesDtoToStoryOccurrence
      );
    const requirementRequestedInStories =
      parsedRes.securityRequirementOverview.requestedStoryOccurrences.map(
        castStoryOccurrencesDtoToStoryOccurrence
      );
    const requirementAntiPatternDetectedInStories = requirementRequestedInStories.filter(
      (story) => story.antiPatterns.length > 0
    );

    return {
      ...requirement,
      coveredStories: requirementCoveredInStories,
      requestedInStories: requirementRequestedInStories,
      antiPatternDetectedInStories: requirementAntiPatternDetectedInStories,
    };
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseRequirementOverview = {
  id?: string;
  applicationId?: string;
  enabled?: boolean;
};

export const useRequirementOverview = ({
  id,
  applicationId,
  enabled = true,
}: UseRequirementOverview) => {
  const { data, isLoading, isFetching, isLoadingError } = useQuery({
    queryKey: SECURITY_FRAMEWORKS_QUERY_KEYS.requirementOverview(id!, applicationId),
    queryFn: async () => await getRequirementOverview(id!, applicationId),
    enabled: enabled && !!id,
  });

  return {
    requirementOverview: data,
    isFetchingRequirementOverview: isLoading || isFetching,
    didFetchingRequirementOverviewErrored: isLoadingError,
  };
};
