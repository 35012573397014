// 3rd
import { z } from 'zod';

// App - Types
import { StatusesDto, castStatusDtoToStatus } from '@/types/story/dtos/status';
import type { StoryOccurrence } from '../../types/requirement';
import { ZodAntiPatternDto, castAntiPatternDtoToAntiPattern } from './anti-pattern';

// App - Other
import { normalizeDatesToISOString } from '@/utils/format';

export const ZodStoryOccurrencesDto = z.object({
  antiPatterns: z.array(ZodAntiPatternDto),
  lastModified: z.string(),
  status: z.enum(StatusesDto),
  storyId: z.string(),
  storyName: z.string(),
  applicationId: z.string().optional(),
  applicationName: z.string().optional(),
});

export type StoryOccurrencesDto = z.infer<typeof ZodStoryOccurrencesDto>;

export const castStoryOccurrencesDtoToStoryOccurrence = (
  dto: StoryOccurrencesDto
): StoryOccurrence => {
  return {
    id: dto.storyId,
    applicationId: dto.applicationId,
    applicationName: dto.applicationName,
    name: dto.storyName,
    status: castStatusDtoToStatus(dto.status),
    lastUpdated: normalizeDatesToISOString(dto.lastModified),
    antiPatterns: dto.antiPatterns.map(castAntiPatternDtoToAntiPattern),
  };
};
