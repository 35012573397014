// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import { ActivityStatusesDto } from '@/types/security-framework/dtos/activity-status';
import { SeveritiesDto, castSeverityToSeverityDto } from '@/types/security-framework/dtos/severity';
import type { CreateRequirement } from '../types/requirement';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { queryClient } from '@/config/lib/react-query';
import { SECURITY_FRAMEWORKS_QUERY_KEYS } from '../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  activityStatus: z.enum(ActivityStatusesDto),
  categoryName: z.string().optional(),
  content: z.string(),
  countermeasuresIds: z.string().array().optional(),
  description: z.string().optional(),
  requirementIdNumber: z.string(),
  securityControlId: z.string(),
  severity: z.enum(SeveritiesDto),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

export { ZodRequestPayloadDto as ZodCreateRequirementRequestPayloadDto };
export type { RequestPayloadDto as CreateRequirementRequestPayloadDto };

// ############
// Response DTO
// ############

const ZodResponseDto = z.object({
  securityRequirementId: z.string(),
});

type ResponseDto = z.infer<typeof ZodResponseDto>;

export { ZodResponseDto as ZodCreateRequirementResponseDto };
export type { ResponseDto as CreateRequirementResponseDto };

// #######
// Request
// #######

export const createRequirement = async (model: CreateRequirement): Promise<string> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({
      activityStatus: 'Active',
      categoryName: model.category,
      content: model.content,
      countermeasuresIds: [],
      // countermeasuresIds: (model.countermeasures || []).map((cm) => cm.id) ?? [],
      description: model.description,
      requirementIdNumber: model.name,
      securityControlId: model.controlId,
      severity: castSeverityToSeverityDto(model.severity),
    });
    const res = await apiClient.post('/SecurityFramework/CreateSecurityRequirement', payload);
    const parsedRes: ResponseDto = ZodResponseDto.parse(res);

    return parsedRes.securityRequirementId;
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseCreateRequirement = {
  onCreateStart?: () => void;
  onCreateSuccess?: (requirementId: string) => void;
  onCreateFailed?: (error: Error) => void;
};

export const useCreateRequirement = ({
  onCreateStart,
  onCreateSuccess,
  onCreateFailed,
}: UseCreateRequirement = {}) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (model: CreateRequirement) => await createRequirement(model),
    onMutate: () => onCreateStart?.(),
    onSuccess: (requirementId) => onCreateSuccess?.(requirementId),
    onError: (error) => onCreateFailed?.(error),
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: SECURITY_FRAMEWORKS_QUERY_KEYS.securityFrameworks(),
      }),
  });

  return {
    createRequirement: mutate,
    isCreating: isPending,
    didCreationErrored: isError,
  };
};
