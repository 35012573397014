// App - Types
import type { Severity } from '@/types/security-framework/severity';

// App - Other
import Locale from '@/locale/en.json';

const locale = Locale.components.translators['security-framework'].severity;

export const translateSeverity = (severity: Severity): string => {
  if (severity === 'high') return locale['High'];

  if (severity === 'medium') return locale['Medium'];

  if (severity === 'low') return locale['Low'];

  return locale['Unknown'];
};

export const translateSeverityToColor = (severity: Severity): string => {
  if (severity === 'high') return '#EC8C7E';

  if (severity === 'medium') return '#FFCA45';

  if (severity === 'low') return '#82B8FE';

  return '#D7E1EE';
};

export const translateSeverityToColorNEW = (severity: Severity): string => {
  if (severity === 'high') return '#E68376';

  if (severity === 'medium') return '#FC9405';

  if (severity === 'low') return '#699AC7';

  return '#D7E1EE';
};

export const translateSeverityToBgColor = (severity: Severity): string => {
  if (severity === 'high') return '#FDE2E2';

  if (severity === 'medium') return '#FDFAE2';

  if (severity === 'low') return '#E2F0FD';

  return '#D7E1EE';
};

export const translateSeverityToBgColorNEW = (severity: Severity): string => {
  if (severity === 'high') return '#F9E0DD';

  if (severity === 'medium') return '#FFF4E6';

  if (severity === 'low') return '#F0F5F9';

  return '#F8FAFC';
};

export const translateSeverityToBgContrastColorNEW = (severity: Severity): string => {
  if (severity === 'high') return '#F6D2CE';

  if (severity === 'medium') return '#FFE6C4';

  if (severity === 'low') return '#DCE7F2';

  return '#D7E1EE';
};
