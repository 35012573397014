// React & Next
import { useCallback, useEffect, useMemo } from 'react';

// 3rd
import { createFilter } from 'react-select';
import { Skeleton, forwardRef } from '@chakra-ui/react';
import type { StyleProps, As, ComponentWithAs } from '@chakra-ui/react';

// App - Types
import type { InputProps } from '@/components/molecules/form';
import type { SecurityFramework } from '../../types/security-framework';

// App - Other
import Locale from '@/locale/en.json';
import { Select } from '@/components/molecules/form';

const locale = Locale.features.frameworks['frameworks-select-requirements-framework'];

type SelectSecurityFrameworkComponent = ComponentWithAs<As, SelectSecurityFrameworkProps> & {
  Loading: typeof Loading;
};

type MandatorySecurityFrameworkFields = Pick<SecurityFramework, 'id' | 'name'>;

type SelectSecurityFrameworkProps = StyleProps &
  Pick<InputProps, 'variant'> & {
    frameworks: MandatorySecurityFrameworkFields[];
    includeDefaultOption?: boolean | string;
    placeholder?: string;
    selected?: MandatorySecurityFrameworkFields | MandatorySecurityFrameworkFields[];
    onChange: (
      selection?: MandatorySecurityFrameworkFields | MandatorySecurityFrameworkFields[]
    ) => void;
    onBlur?: () => void;
    isDisabled?: boolean;
    isMulti?: boolean;
    isSearchable?: boolean;
  };

export const SelectSecurityFramework = forwardRef(
  (
    {
      variant,
      frameworks,
      includeDefaultOption,
      placeholder,
      selected,
      onChange,
      onBlur,
      isDisabled,
      isMulti,
      isSearchable,
      ...props
    }: SelectSecurityFrameworkProps,
    ref
  ) => {
    const defaultOption = useMemo(
      () => ({
        value: '',
        label: typeof includeDefaultOption === 'string' ? includeDefaultOption : locale['All'],
      }),
      [includeDefaultOption]
    );

    const options = useMemo(() => {
      const frameworkOptions = frameworks
        .map((framework) => ({
          value: framework.id,
          label: framework.name,
        }))
        .sort((a, b) =>
          a.label.localeCompare(b.label, undefined, {
            numeric: true,
          })
        );

      if (!!includeDefaultOption && frameworkOptions.length > 1)
        return [defaultOption, ...frameworkOptions];

      return frameworkOptions;
    }, [defaultOption, frameworks, includeDefaultOption]);

    const currentSelection = useMemo(() => {
      if (selected && Array.isArray(selected)) {
        return selected.map((framework) => ({
          value: framework.id,
          label: framework.name,
        }));
      } else if (selected) {
        return {
          value: selected.id,
          label: selected.name,
        };
      }

      const hasDefaultOption = options.some((option) => option.value === defaultOption.value);

      if (hasDefaultOption) {
        return defaultOption;
      }

      return undefined;
    }, [defaultOption, options, selected]);

    const getCurrentSelection = useCallback(
      (optionValue: string) => {
        return frameworks.find((framework) => framework.id === optionValue);
      },
      [frameworks]
    );

    useEffect(() => {
      if (!currentSelection && options.length === 1) {
        if (isMulti) {
          onChange([getCurrentSelection(options[0].value)] as MandatorySecurityFrameworkFields[]);
        } else {
          onChange(getCurrentSelection(options[0].value) as MandatorySecurityFrameworkFields);
        }
      }
    }, [onChange, options, currentSelection, isMulti, getCurrentSelection]);

    return (
      <Select
        useBasicStyles
        isMulti={isMulti}
        variant={variant || 'old.outline'}
        size="sm"
        options={options}
        value={currentSelection}
        placeholder={placeholder}
        selectedOptionColor="surface.brand.primary"
        onChange={(selectedOptions) => {
          if (!selectedOptions) {
            return onChange();
          }

          if (isMulti && Array.isArray(selectedOptions)) {
            return onChange(
              selectedOptions.map(
                (option) => getCurrentSelection(option.value) as MandatorySecurityFrameworkFields
              )
            );
          } else if (selectedOptions) {
            const selectedOption = getCurrentSelection(
              (selectedOptions as { value: string }).value
            );

            return onChange(selectedOption);
          } else {
            return onChange();
          }
        }}
        onBlur={onBlur}
        isDisabled={isDisabled}
        ref={ref}
        isSearchable={!!isSearchable}
        filterOption={createFilter({
          stringify: (option) => option.label,
        })}
        chakraStyles={{
          container: (styles) => ({
            maxW: '100%',
            w: 'fit-content',
            minW: '165px',

            ...styles,
            ...props,
          }),
          menuList: (styles) => ({
            ...styles,
            ...props,
          }),
          clearIndicator: (styles) => ({
            '& .chakra-icon': {
              width: '6px',
              height: '6px',
            },

            ...styles,
          }),
        }}
      />
    );
  }
) as SelectSecurityFrameworkComponent;

type LoadingProps = StyleProps;

const Loading = ({ ...props }: LoadingProps) => {
  return <Skeleton {...props} height="26px" />;
};

SelectSecurityFramework.Loading = Loading;
